<template>
  <div class="table">
    <header style="display:flex;justify-content: space-between;margin-bottom: 15px;">
      <h2>词库列表管理</h2>
      <div>
        <el-button @click="$router.push('/risk/words/test')">测试</el-button>
      </div>
    </header>
    <DragTable :list-query="list" :header="header" :sort="false">
      <template #control="{ row }">
        <span class="word-color" @click="ctl(row)">编辑</span>
      </template>
    </DragTable>
  </div>
</template>
<script>
import { defineComponent, reactive } from "@vue/composition-api";
import SwitchText from "../../../components/common/SwitchText.vue";
export default defineComponent({
  components: { SwitchText },
  setup(props, { root }) {
    const header = [
      { name: "适用对象", value: "target" },
      { name: "词库类型", value: "type" },
      { name: "操作", value: "", slot: "control", width: 80 },
      { name: "操作人", value: "operator" },
      { name: "变更日期", value: "update_at" },
      { name: "词数", value: "total" }
    ];
    const list = reactive({ data: [] });
    const getList = () => {
      root.$axios
        .get("/rcp/dictionaryManage/dictionary/list")
        .then((res) => {
          list.data = res.data;
        });
    };
    const ctl = async (data) =>{
      root.$router.push(`/risk/wordsDetail?id=${data.id}&state=${data.type}`)
    }
    getList();
    return {
      list,
      header,
      getList,
      ctl
    };
  },
});
</script>
<style lang="scss" scoped>
.table {
  width: 100%;
  height: 100%;
  // display: flex;
  // flex-direction: column;
}
.search-top {
  display: flex;
  margin-top: 15px;
  & > div {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    margin-right: 15px;
    span {
      font-size: 14px;
      white-space: nowrap;
      text-align: right;
      flex-shrink: 0;
    }
    &:nth-of-type(2) {
      span {
        width: 70px;
      }
    }
    &:nth-of-type(3) {
      .input {
        width: 350px;
      }
    }
  }
}
.status {
  color: $safeColor;
  position: relative;
  padding-left: 13px;
  &.red {
    color: $dangerColor;
  }
}
.control {
  display: flex;
  span {
    margin-right: 14px;
    cursor: pointer;
    font-size: 14px;
    color: $mainColor;
    &:last-of-type(1) {
      margin-right: 0;
    }
    &.red{
      color: $dangerColor;
    }
    &.green{
      color: $safeColor;
    }
  }
}
.table-img {
  width: 100%;
  object-fit: contain;
}
.table-footer {
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
